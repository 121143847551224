import React, { useState, useEffect } from 'react'
import { useParams, Link } from "react-router-dom";
import {
  Radio,
  message,
  Timeline,
  Image,
  Layout,
  Row,
  Popover,
  Col,
  Card,
  Typography,
  Tag,
  Space,
  List,
  Popconfirm,
  Button,
  Divider,
  Upload,
  Empty,
  Tooltip,
  Avatar
} from 'antd'
import MenuServiceDetail from '../../../components/services/MenuServiceDetail'
import SectionTitle from '../../../components/common/elements/SectionTitle/SectionTitle'
import { getServiceDetail, finishRepair, closeOrder } from '../../../redux/serviceOrderDuck'
import { Global, css } from "@emotion/react";
import {
  assignStepFlow, getHistory, changeStatusStopStart
} from "../../../redux/workflowDuck";
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { axiosApi, urlfull, tenantSelected } from "../../../lib/utils/axiosApi";
import { changeLangAction } from '../../../redux/settingsDuck';
import "../../../components/sideBarDerecha2/SiderBarRight.css"
import ServiceLayout from '../../../layouts/ServiceLayout';
import KButton from "../../../components/common/elements/KButton/KButton";
import {
  PlayCircleOutlined,
  InboxOutlined,
  PlaySquareOutlined,
  ClockCircleOutlined, FileAddOutlined,
  PaperClipOutlined,
  LoadingOutlined,
  BlockOutlined,
  EyeOutlined,
  CheckOutlined,
  DeleteOutlined,
  CaretRightFilled,
  PauseCircleFilled,
  PauseOutlined,
  ClockCircleFilled,
} from "@ant-design/icons";
import KModal from "../../../components/common/elements/KModal/KModal";
import FormProcessChange from "../../../components/services/FormProcessChange";
import moment from 'moment'
import _ from 'lodash'
import 'animate.css';
import { isImage, isVideo, getTypeFile } from "../../../lib/utils/utils";
import MainLayoutv2 from '../../../layouts/MainLayoutv2';
import useResponsive from '../../../hooks/useResponsive'
const { Title, Text } = Typography;
const { Dragger } = Upload;

const ServiceRepair = ({
  changeLangAction,
  assignStepFlow,
  intl,
  settings,
  getHistory,
  changeStatusStopStart,
  getServiceDetail,
  serviceOrder,
  workflow,
  closeOrder,
  finishRepair,
  ...props }) => {

  let { id } = useParams();
  const [visibleModalService, setVisibleModalService] = useState(false)
  const [visibleModalFinish, setVisibleModalFinish] = useState(false)
  const [visibleModalFiles, setVisibleModalFiles] = useState(false)
  const [loading, setLoading] = useState(false)
  const [repairFinished, setRepairFinished] = useState(false)
  const [propsDragger, setPropsDragger] = useState(null)
  const [numFiles, setNumFiles] = useState(null)
  const [files, setFiles] = useState(null)
  const [currentFlowStep, setCurrentFlowStep] = useState(null)
  const [visibleFilesModal, setVisibleFilesModal] = useState(false)
  const [visiblePartsModal, setVisiblePartsModal] = useState(false)
  const [startTime, setStartTime] = useState(moment().utcOffset(0));
  const [timer, setTimer] = useState(0)
  const [openConfirm, setOpenConfirm] = useState(false)
  const { isMobile, isTablet } = useResponsive()

  const tenantJAC = 'jacmexico'
  const { lang } = settings;

  useEffect(() => {
    moment.locale(lang);
    console.log(moment().format("MMMM"));
  }, [lang])


  const timeFormat = (secs) => {

    secs = secs * 60;
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;

    return (
      hours.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      " hrs " +
      minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
      + " mins"
    );


  };



  useEffect(() => {
    if (serviceOrder.serviceDetail) {
      setTimer(serviceOrder.serviceDetail["time_elapsed_in_minutes"]);
    }
    const interval = setInterval(() => {
      if (serviceOrder.serviceDetail["WORKSHOP"].current_step.status === "PLAY") {
        setTimer(timer => timer + 1);
      }
    }, 1000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [serviceOrder]);



  const onFinish = (values) => {
    if (values.step === -1 || values.step === -2) {
      console.log('finish', 'finish de reparacion')
      setLoading(true)
      finishRepairProcess(values.step, values)
    } else {
      assignStepFlow(values).then(() => {
        setVisibleModalService(false)
        getServiceDetail(id);
        if (serviceOrder?.serviceDetail) {
          getHistory('WORKSHOP', serviceOrder.serviceDetail.id)
        }
      })
    }

  }

  useEffect(() => {
    resetpropsFile()
    if (id) {
      console.log("id", id);
      getServiceDetail(id);
    }
  }, [])


  useEffect(() => {
    if (serviceOrder?.serviceDetail) {
      getHistory('WORKSHOP', serviceOrder.serviceDetail.id)
    }
  }, [serviceOrder])

  useEffect(() => {
    if (serviceOrder?.serviceDetail?.process_status) {
      setRepairFinished(serviceOrder.serviceDetail.process_status)
    }
  }, [serviceOrder?.serviceDetail])

  const sendFiles = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("order", serviceOrder.serviceDetail.id);
      formData.append("workflow_step_log", currentFlowStep.id);
      const res = await axiosApi.post('/service/order-file/', formData)
      onSuccess(file);
    } catch (e) {
      const error = new Error('Fallido');
      onError({ event: error });
    }
  }

  const deleteStepLog = async (stepLog) => {
    try {
      setLoading(true)
      const stepLogId = stepLog?.id
      const res = await axiosApi.delete(`/workflow/workflow-step-log/${stepLogId}`)
      if (!res.status) {
        message.error(intl.formatMessage({ id: 'repair.statusChangeError' }));
      } else {
        message.success(intl.formatMessage({ id: 'repair.statusChangeSuccess' }));
        getServiceDetail(id);
      }
    } catch (e) {
      const error = new Error('Fallido');
    } finally {
      setLoading(false)
    }
  }

  const resetpropsFile = () => {
    setPropsDragger({
      name: 'file',
      multiple: true,
      accept: 'image/*,video/*',
      showUploadList: true
    })
  }


  const changeStepStatus = async (action, stepId) => {
    console.log(action, stepId)
    action = action === 'PLAY' ? 'stop' : 'start'
    try {
      const res = await changeStatusStopStart(action, stepId);
      console.log(res)
      if (!res.status) {
        message.error(intl.formatMessage({ id: 'repair.statusChangeError' }));
      } else {
        message.success(intl.formatMessage({ id: 'repair.statusChangeSuccess' }));
        getServiceDetail(id);
      }
    } catch (e) {
      alert('Hubo un error, porfavor vuelva a intentar')
    }

  }

  const timeBetweenTwoDates = (idx) => {
    if (workflow?.workflow_logs && idx !== 0) {
      let fechaInicio = moment(workflow?.workflow_logs[idx - 1]?.timestamp)
      let fechaFin = moment(workflow?.workflow_logs[idx]?.timestamp)
      let duration = fechaInicio.diff(fechaFin, 'hours', true);
      duration = parseFloat(duration)
      let type = 'hr'
      if (duration > 1) {
        type = 'hrs'
      }
      if (duration < 1) {
        duration = fechaInicio.diff(fechaFin, 'minutes', true);
        type = 'mins'
      }
      return `${duration <= 1 ? Math.round(duration) : duration.toFixed(2)} ${type}`
    } else {
      return ''
    }

  }

  const finishRepairProcess = async (step = -1, data = null) => {
    setLoading(true)
    try {
      console.log('data====', data)
      if (step === -2 && data) {
        data.close_order = true;
      }
      const res = await finishRepair(id, data)
      if (res.success) {
        message.success(intl.formatMessage({ id: 'repair.statusChangeSuccess' }));
        setTimeout(() => {
          setLoading(false)
          window.location.reload();
        }, 500);
      } else {
        message.error(res?.error?.response?.data?.message ? res?.error?.response?.data?.message : 'Hubo un error al cambiar de estatus, porfavor vuelve a intentar');
      }

      // si es step -2 entonces significa que hay que hacer tambi{en la entrega}
      // if(step===-2 && data){
      //   closeOrderService(data)
      // }else{
      //   setTimeout(()=>{
      //     //setLoading(false)
      //     window.location.reload();
      //   }, 500);
      // }

    } catch (e) {
      alert('Hubo un error, porfavor vuelva a intentar')
    } finally {
      setVisibleModalFinish(false)
      setLoading(false)
    }

  }


  const closeOrderService = async (data) => {
    setLoading(true)
    try {
      const res = await closeOrder(id, {
        closing_reason: data.closing_reason,
        closing_datetime: data.closing_datetime
      })
      if (res.success === false) {
        message.error('Hubo un error al entregar el expediente, por favor intenta nuevamente')
      } else {
        message.success('Expediente cerrado corectamente')
        setTimeout(() => {
          //setLoading(false)
          window.location.reload();
        }, 500);
      }
    } catch (e) {

    } finally {
      setLoading(false)
    }
  }




  return (
    <>
      <Global
        styles={css`
            .itemDescripcion > .ant-form-item-label > label {
              font-weight: normal !important;
            }

            .modalfiles .ant-modal-body {
              height: 700px;
            }
            .repair_history_title {
              font-size: 20px;
              margin-left: 30px;
            }
            .card_workflow_logs {
              background: #09072f0a !important;
              border-radius: 11px;
              padding: 20px 0px;
            }
            .card_workflow_logs .ant-card-body {
              position: relative;
            }
            .timeLineMonth {
              font-size: 11px;
              color: var(--primaryColor);
            }
            .timeLineDate {
              font-size: 24px;
              color: var(--primaryColor);
            }
            .timeLineIndicatorLast {
              background: var(--colorSuccess);
              width: 32px;
              height: 32px;
              border-radius: 20px;
              display: flex;
            }
            .timeLineIndicator {
              background: var(--primaryColor);
              width: 32px;
              height: 32px;
              border-radius: 20px;
              display: flex;
            }
            .timeLineIndicator .anticon-check {
              margin: auto;
              color: white;
            }
            .cardItemTimeLine {
              width: 100%;
              height: 100%;
              padding: 15px 0px;
              border-radius: 10px;
              line-height: 1.3;
            }
            .titleCardTimeLine {
              font-size: 20px;
              color: #08082f;
              letter-spacing: -0.11px;
            }
            .dateCardTimeLine {
              font-size: 14px;
              color: #090731;
            }
            .lineVertical {
              position: absolute;
              background: #341eff;
              width: 2px;
              left: 98.5px;
              top: 50px;
              bottom: 50px;
            }
            .titleCurrentStep {
              background: #f7f7f7;
              border: 1px solid #2d2cf5;
              border-radius: 32px;
              text-align: center;
              font-size: 25px;
              color: #08082f;
              padding: 0px 20px;
              margin-top: 20px;
            }
            .tagStatus {
              background: var(--colorSuccess);
              padding: 5px 35px;
              border-radius: 30px;
            }
            .btn-play-stop {
              border-radius: 20px;
              color: white;
            }
            .btn-red-status {
              background: #cf2b52;
            }
            .btn-blue-status {
              background: var(--primaryColor);
            }
            .imageProfileAssigned {
              width: 110px;
              height: 110px;
            }
            .titleAssigned {
              font-size: 18px;
              color: #09072f;
            }
            .assignedContent {
              margin-top: auto;
              margin-bottom: auto;
            }
            .colAssigned {
              padding: 20px;
              display: flex;
            }
            .nameAssignedTeg {
              border-radius: 6px;
              background: #f0f0fa;
              padding: 0 50px;
              font-size: 20px;
            }
            .bg_red{
              background:red;
              color: white;
            }
          `}
      />
      <MainLayoutv2
        isServiceDetail={true}
        title={intl.formatMessage({ id: "servicesDetails.servicesDetails" })}
        index="expedientes"
        loading={loading}
      >
        <Layout style={{ marginTop: 10 }}>
          <Card className="card_details_service">
            <MenuServiceDetail menuSelected={4} />
            {serviceOrder.serviceDetail?.current_step?.step && (
              <Row justify={"left"} style={{ padding: 20 }} gutter={[16, 16]}>
                <Col xs={24} lg={8} style={{ paddingRight: 30 }}>
                  <Text className="repair_history_title">
                    {intl.formatMessage({
                      id: "repair.currentProcess",
                    })}
                    
                    {
                      serviceOrder.serviceDetail["WORKSHOP"].current_step.step
                        .step_type==='REPAIR' ? ' (R)' : ' (P)'
                    }


                  </Text>
                  <div className="titleCurrentStep">
                    {
                      serviceOrder.serviceDetail["WORKSHOP"].current_step.step
                        .name
                    }

                    
                  </div>

                  <Row justify="space-between" style={{ marginTop: 30 }}>
                    <Col>
                      <span
                        className={
                          serviceOrder.serviceDetail["WORKSHOP"]?.current_step
                            ?.status === "PLAY"
                            ? "animate__animated animate__pulse animate__infinite tagStatus"
                            : "tagStatus bg_red"
                        }
                      >
                        {
                          serviceOrder?.serviceDetail?.process_status !== 'REPAIR-COMPLETED' ? intl.formatMessage({
                            id:
                              serviceOrder.serviceDetail["WORKSHOP"]
                                .current_step.status === "PLAY"
                                ? "started"
                                : "stopped",
                          }) : 'Reparación Finalizada'
                        }
                      </span>
                    </Col>
                    <Col>
                      <Space size={30}>
                        <span>
                          <ClockCircleOutlined style={{ marginRight: 10 }} />
                          {timer && timeFormat(timer)}
                        </span>
                        {
                          serviceOrder?.serviceDetail?.process_status !== 'REPAIR-COMPLETED' && <Popconfirm
                            title={intl.formatMessage({
                              id:
                                serviceOrder.serviceDetail["WORKSHOP"]
                                  .current_step.status === "PLAY"
                                  ? "repair.stopProcess"
                                  : "repair.initProcess",
                            })}
                            onConfirm={() =>
                              changeStepStatus(
                                serviceOrder.serviceDetail["WORKSHOP"]
                                  .current_step.status,
                                serviceOrder.serviceDetail["WORKSHOP"]
                                  .current_step.id
                              )
                            }
                            okButtonProps={{ loading: workflow.fetching }}
                          >
                            <Tooltip
                              placement="bottom"
                              title={
                                serviceOrder.serviceDetail["WORKSHOP"]
                                  .current_step.status === "PLAY"
                                  ? "Detener"
                                  : "Continuar"
                              }
                            >
                              <Button
                                className={
                                  serviceOrder.serviceDetail["WORKSHOP"]
                                    .current_step.status === "PLAY"
                                    ? "btn-play-stop btn-red-status"
                                    : "btn-play-stop btn-blue-status"
                                }
                                icon={
                                  serviceOrder.serviceDetail["WORKSHOP"]
                                    .current_step.status === "PLAY" ? (
                                    <PauseOutlined />
                                  ) : (
                                    <CaretRightFilled />
                                  )
                                }
                              />
                            </Tooltip>
                          </Popconfirm>
                        }

                      </Space>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 25 }}>
                    <Col md={12}>
                      <Avatar
                        src={
                          serviceOrder.serviceDetail["WORKSHOP"]?.current_step
                            ?.assigned_to?.image_profile || 'KIKERT'
                        }
                        className="imageProfileAssigned"
                      />
                    </Col>
                    <Col className="colAssigned" md={12}>
                      <div className="assignedContent">
                        <Space direction="vertical" size={5}>
                          <Text className="titleAssigned">Asignado a</Text>
                          <div className="nameAssignedTeg" style={{ fontSize: 16 }}>
                            <b>
                              {
                                serviceOrder.serviceDetail["WORKSHOP"]
                                  ?.current_step?.assigned_to?.first_name
                              }{" "}
                              {
                                serviceOrder.serviceDetail["WORKSHOP"]
                                  ?.current_step?.assigned_to?.last_name
                              }

                            </b>

                          </div>
                          {
                            serviceOrder?.serviceDetail?.current_location && <Text >Locación: <b>{serviceOrder?.serviceDetail?.current_location?.name}</b></Text>
                          }
                        </Space>
                      </div>
                    </Col>
                  </Row>
                  <Row justify="space-between" style={{ marginTop: 20 }}>
                    <Col>
                      <KButton
                        type={"secondary"}
                        disabled={serviceOrder?.serviceDetail?.process_status === 'REPAIR-COMPLETED'}
                        onClick={() => setVisibleModalService(true)}
                        text={intl.formatMessage({
                          id: "repair.changeStatus",
                        })}
                      />
                    </Col>
                    {/*{*/}
                    {/*  serviceOrder?.serviceDetail?.process_status!=='REPAIR-COMPLETED' && <Col>*/}
                    {/*        <KButton*/}
                    {/*            type={"primary"}*/}
                    {/*            onClick={() => setVisibleModalFinish(true)}*/}
                    {/*            text={"Finalizar reparación"}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*}*/}

                  </Row>
                </Col>
                <Col xs={24} lg={14}>
                  <Text className="repair_history_title">
                    {intl.formatMessage({ id: "repair.historic" })}
                  </Text>
                  <Card
                    className="card_workflow_logs"
                  // extra={<a href="#">More</a>}
                  >
                    {/*<div className="lineVertical" />*/}
                    {workflow.workflow_logs &&
                      workflow.workflow_logs.map((item, i) => {
                        return (
                          <>
                            <Row style={{ marginBottom: 10 }}>
                              <Col
                                style={{
                                  textAlign: "center",
                                  display: "flex"
                                }}
                              >

                                <div
                                  style={{ margin: "auto", lineHeight: 1, width: 100 }}
                                >
                                  <Text className="timeLineMonth">
                                    {moment(item.timestamp).format("MMM").toUpperCase()}
                                  </Text>
                                  <br />
                                  <Text className="timeLineDate">
                                    {moment(item.timestamp).format("DD")}
                                  </Text>
                                  {/*
                                              (isMobile || isTablet) ? <Text style={{display:'block'}}>
                                                  {item?.timestamp ?
                                                  timeBetweenTwoDates(i):''}
                                              </Text>:(
                                                <small style={{display:'block',position:'relative',top:'-85px'}}>{item?.timestamp ?
                                                  timeBetweenTwoDates(i):''}</small>
                                              )  
                                                */}



                                </div>

                              </Col>

                              <Col flex="auto">
                                <Card className="cardItemTimeLine">

                                  <Space>
                                    <span
                                      className={
                                        i === 0
                                          ? "timeLineIndicatorLast"
                                          : "timeLineIndicator"
                                      }
                                    >
                                      {i !== 0 && <CheckOutlined />}
                                    </span>
                                    <Text className="titleCardTimeLine">
                                      {item.step.name}
                                      {" - "}
                                      {item?.assigned_to &&
                                        `${item.assigned_to.first_name} ${item.assigned_to.last_name}`}
                                      
                                    </Text>
                                    <small>{item.step_type==='REPAIR' ? ' (R)' : '(P)'}</small>
                                  </Space>
                                  <small style={{ display: 'block' }}>
                                    <b> {item?.timestamp ? `Duró: ${timeBetweenTwoDates(i)}`
                                      : ''}</b>
                                  </small>

                                  <br />
                                  <br />
                                  <Text className="dateCardTimeLine">
                                    Inició:&nbsp;
                                    {moment(item.timestamp).format(
                                      "DD/MM/YYYY hh:mm a"
                                    )}
                                  </Text>
                                  <br />

                                  {
                                    <Tooltip
                                      placement={"top"}
                                      title={"Añadir evidencia"}
                                    >
                                      <KButton
                                        type={"secondary"}
                                        size={"small"}
                                        onClick={() => {
                                          setCurrentFlowStep(item);
                                          setVisibleModalFiles(true);
                                        }}
                                        text={
                                          <p>
                                            <PaperClipOutlined />
                                          </p>
                                        }
                                      />
                                    </Tooltip>
                                  }
                                  {
                                    <Tooltip
                                      placement={"top"}
                                      title={"Eliminar"}
                                    >
                                      <Popconfirm
                                        title="¿Estás seguro de eliminar este registro?"
                                        description="Esta acción no es reversible"
                                        open={openConfirm}
                                        placement="topLeft"
                                        onConfirm={() => { deleteStepLog(item) }}
                                        okButtonProps={{ loading: loading }}
                                        onCancel={() => { setOpenConfirm(false) }}
                                        okText="Eliminar"
                                        cancelText="Cancelar"
                                      >
                                        <KButton
                                          type={"error"}
                                          size={"small"}
                                          onClick={() => {
                                            setOpenConfirm(true)
                                          }}
                                          disabled={workflow.workflow_logs?.length === 1}
                                          loading={loading}
                                          text={
                                            <p>
                                              <DeleteOutlined />
                                            </p>
                                          }
                                        />
                                      </Popconfirm>
                                    </Tooltip>
                                  }
                                  {
                                    <Tooltip
                                      placement={"top"}
                                      title={"Ver evidencia"}
                                    >
                                      <KButton
                                        type={"primary"}
                                        size={"small"}
                                        onClick={() => {
                                          setCurrentFlowStep(item);
                                          setVisibleFilesModal(true);
                                        }}
                                        text={
                                          <p>
                                            <EyeOutlined /> (
                                            {item?.files ? item?.files.length : 0}
                                            ){" "}
                                          </p>
                                        }
                                      />
                                    </Tooltip>
                                  }


                                  {
                                    tenantSelected === tenantJAC &&
                                    <Tooltip
                                      placement={"top"}
                                      title={"Ver partes"}
                                    >
                                      <KButton
                                        type={"secondary"}
                                        size={"small"}
                                        onClick={() => {
                                          setCurrentFlowStep(item);
                                          setVisiblePartsModal(true);
                                        }}
                                        text={
                                          <p>
                                            <BlockOutlined />
                                          </p>
                                        }
                                      />
                                    </Tooltip>
                                  }


                                </Card>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                  </Card>
                </Col>
              </Row>
            )}

            <Row
              gutter={14}
              justify={"center"}
              style={{ paddingTop: 30, paddingBottom: 30 }}
            >
              {!serviceOrder.serviceDetail?.current_step?.step && (
                <Col lg={8} sm={24}>
                  <KButton
                    size={"large"}
                    type={"success"}
                    onClick={() => setVisibleModalService(true)}
                    text={
                      <>
                        <PlayCircleOutlined
                          style={{ fontSize: 30, marginTop: 5 }}
                        />
                        <p>
                          {intl.formatMessage({ id: "repair.initprocess" })}
                        </p>
                      </>
                    }
                    style={{ width: "100%", height: 80 }}
                  />
                </Col>
              )}
            </Row>
          </Card>
        </Layout>

        <KModal
          title={`Partes`}
          onCancel={() => setVisiblePartsModal(false)}
          visible={visiblePartsModal}
        >
          <p style={{ fontSize: 20, marginBottom: 20 }}><b>{currentFlowStep?.step.name}</b></p>
          <List
            dataSource={currentFlowStep?.spareparts}
            renderItem={item => <List.Item>{item.name}</List.Item>}
          />
        </KModal>

        <KModal
          className={"modalfiles"}
          title={intl.formatMessage({ id: "services.files.files" })}
          onCancel={() => {
            console.log(currentFlowStep);
            setNumFiles(null);
            setFiles(null);
            resetpropsFile();
            getServiceDetail(id);
            setVisibleModalFiles(false);
          }}
          destroyOnClose={true}
          visible={visibleModalFiles}
        >
          <Dragger {...propsDragger} customRequest={sendFiles}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Elige los archivos que serán relacionados a este proceso de
              reparación
            </p>
            <p className="ant-upload-hint">
              soporta archivos tipo imagen y video
            </p>
            {numFiles && <p>Archivos : {numFiles}</p>}
          </Dragger>

          <KButton
            type={"outline"}
            loading={loading}
            text={"Cerrar"}
            style={{ display: "block", marginTop: 30 }}
            onClick={() => {
              setNumFiles(null);
              getServiceDetail(id);
              setFiles(null);
              setVisibleModalFiles(false);
              resetpropsFile();
            }}
          />
        </KModal>

        <KModal
          title={intl.formatMessage({ id: "servicesDetails.repair" })}
          onCancel={() => setVisibleModalService(false)}
          loading={loading}
          visible={visibleModalService}
        >
          <FormProcessChange
            assignmentRequired={true}
            onCancel={() => setVisibleModalService(false)}
            loading={(workflow.fetching || loading) ? true : false}
            onSubmit={onFinish}
            current_location={serviceOrder?.serviceDetail?.current_location}
            orderId={
              serviceOrder?.serviceDetail && serviceOrder.serviceDetail.id
            }
            workflowCode={"WORKSHOP"}
          />
        </KModal>

        <KModal
          title={"Archivos"}
          onCancel={() => setVisibleFilesModal(false)}
          visible={visibleFilesModal}
        >
          <Row>
            {currentFlowStep?.files &&
              currentFlowStep.files.map((item, i) => {
                return (
                  <Col span={6}>
                    {isImage(item.file) && (
                      <Image
                        key={i}
                        style={{
                          width: "100%",
                          height: "100%",
                          background: "gray",
                        }}
                        src={item.file}
                      />
                    )}

                    {isVideo(item.file) && (
                      <a target={"_blank"} href={item.file}>
                        <PlaySquareOutlined
                          style={{ fontSize: 100, cursor: "pointer" }}
                          className={"text-primary"}
                        />
                      </a>
                    )}
                  </Col>
                );
              })}
          </Row>

          {currentFlowStep?.files && currentFlowStep?.files?.length <= 0 && (
            <Empty description={"Sin datos"} />
          )}
        </KModal>

        <KModal
          title={intl.formatMessage({ id: "servicesDetails.repair" })}
          onCancel={() => {
            setVisibleModalFinish(false);
          }}
          visible={visibleModalFinish}
        >
          <p>¿Finalizar reparación?</p>
          <KButton
            type={"outline"}
            loading={loading}
            text={"Cancelar"}
            style={{ marginRight: 10 }}
            onClick={() => {
              setVisibleModalFinish(false);
            }}
          />
          <KButton
            loading={loading}
            text={"Confirmar"}
            onClick={() => finishRepairProcess()}
          />
        </KModal>
      </MainLayoutv2>
    </>
  );
}


const mapStateToProps = state => ({
  settings: state.settings,
  serviceOrder: state.serviceOrder,
  workflow: state.workflow
})

export default injectIntl(connect(mapStateToProps, {
  getHistory,
  changeLangAction,
  changeStatusStopStart,
  finishRepair,
  getServiceDetail,
  closeOrder,
  assignStepFlow
})(ServiceRepair))


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Divider,
  Layout,
  Typography,
  Alert,
  Spin,
  Button,
  Select,
  message,
  Card,
  Tag,
  Popconfirm,
  Badge,
  Space,
} from "antd";
import { Global, css } from "@emotion/react";
import moment from 'moment'
import {
  UserOutlined,
  FullscreenOutlined,
  PlayCircleFilled,
  PauseCircleFilled,
  TableOutlined,
  ClockCircleFilled,
} from "@ant-design/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import Board from "react-trello";
import { axiosApi, API } from "../../../lib/utils/axiosApi";
import FormProcessChange from "../../../components/services/FormProcessChange";
import KModal from "../../../components/common/elements/KModal/KModal";
import KButton from "../../../components/common/elements/KButton/KButton";
import { useHistory, useParams } from "react-router-dom";
import {
  assignStepFlow,
  changeStatusStopStart,
} from "../../../redux/workflowDuck";
import "animate.css";
import { finishRepair } from '../../../redux/serviceOrderDuck'
import useSettingsKikert from "../../../hooks/useSettingsKikert";
import MainLayoutv2 from "../../../layouts/MainLayoutv2";


const Electroplan_ = ({ intl, assignStepFlow, finishRepair, changeStatusStopStart, ...props }) => {
  /* const [eventBus, setEventBus] = useState(null); */
  let eventBus = undefined;
  const { Option } = Select;
  const { Text } = Typography

  const [visibleModalService, setVisibleModalService] = useState(false);
  const [orderSelected, setOrderSelected] = useState()
  const [allData, setAllData] = useState([]);
  const [optionSelected, setOptionSelected] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [orderAssigned_to, setOrderAssigned_to] = useState(null)
  const [currentWorkFlow, setCurrentWorkFlow] = useState(null)
  const [assignmentRequired, setAssignmentRequired] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dataSource, setDataSource] = useState({
    lanes: [],
  });
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [loadColumns, setLoadColumns] = useState(false);
  const [flag, setFlag] = useState(false);
  const [subCategoryRepair, setSubCategoryRepair] = useState(1)
  const { settingsKikert, getMetaValue } = useSettingsKikert()
  const HIDE_LICENSE_PLATE_INPUT = getMetaValue('HIDE_LICENSE_PLATE_INPUT');
  const ECONOMIC_NUMBER_LABEL = getMetaValue('ECONOMIC_NUMBER_LABEL');
  let setEventBus = (handle) => {
    eventBus = handle;
  };
  const history = useHistory()






  const getInfo = async () => {
    try {
      let settings = localStorage.getItem('settings_kk')
      settings = JSON.parse(settings)
      const branchSelected = settings?.branchSelected?.id
      let response = await axiosApi.get(API.WORKFLOW.WORKFLOW);
      if (response.status === 200) {
        const filteredData = response.data.results.filter((i) => !i.branch || i.branch === branchSelected)
        setAllData(filteredData);
        setOptionSelected(filteredData[0].id);
        setCurrentWorkFlow(filteredData[0].order_type.code);
        setAssignmentRequired(filteredData[0].assignment_required);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (!loading) {
      getServices();
    }


  }, [optionSelected]);

  /* useEffect(() => {
    const interval = setInterval(() => {
      getServices();
    }, 10000);
    return () => clearInterval(interval);    
  }, []); */

  useEffect(() => {
    const interval = setInterval(() => {
      setFlag((flag) => !flag);
    }, 60000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);


  useEffect(() => {
    if (!loading) {
      getServices();
    }

  }, [flag])


  useEffect(() => {
    setLoadingData(true)
    getServices()
  }, [subCategoryRepair])





  /* useEffect(() => {
    getServices();
    console.log("flag =>", flag);
  }, [flag]) */
  const get_board_location = (data) => {
    let steps = []
    data.map((item, idx) => {
      if (item.current_location === null) {
        let i = steps.findIndex((element) => element.id === '0');
        if (i < 0) {
          steps.push({
            id: `${0}`,
            title: 'Sin asignar',
            label: "",
            cards: [
              {
                id: `${item.id}`,
                folio: item.folio,
                customer:
                  item.customer.first_name + " " + item.customer.last_name,
                unit:
                  item.order_dynamic_data?.car_model?.brand?.name ||
                  item.order_dynamic_data?.vehicle?.model?.brand?.name,
                model:
                  item.order_dynamic_data?.car_model?.name ||
                  item.order_dynamic_data?.vehicle?.model?.name,
                assigned_to_id: item?.current_step?.assigned_to?.id,
                assigned_to:
                  item?.current_step?.assigned_to?.first_name + ' ' +
                  item?.current_step?.assigned_to?.last_name,
                step: item.current_step,
                type: item.order_type,
                vin: item.order_dynamic_data?.vehicle?.vin,
                license_plate: item.order_dynamic_data?.vehicle?.license_plate,
              }
            ],
          });
        } else {
          steps[i]['cards'].push(
            {
              id: `${item.id}`,
              folio: item.folio,
              orderWorkshop: item,
              customer:
                item.customer.first_name + " " + item.customer.last_name,
              unit:
                item.order_dynamic_data?.car_model?.brand?.name ||
                item.order_dynamic_data?.vehicle?.model?.brand?.name,
              model:
                item.order_dynamic_data?.car_model?.name ||
                item.order_dynamic_data?.vehicle?.model?.name,
              assigned_to_id: item?.current_step?.assigned_to?.id,
              assigned_to:
                item?.current_step?.assigned_to?.first_name + ' ' +
                item?.current_step?.assigned_to?.last_name,
              step: item.current_step,
              type: item.order_type,
              vin: item.order_dynamic_data?.vehicle?.vin,
              license_plate: item.order_dynamic_data?.vehicle?.license_plate,
            }
          )
        }
      } else {
        let i = steps.findIndex((element) => element.id == item.current_location.id);
        if (i < 0) {
          steps.push({
            id: `${item.current_location?.id}`,
            title: `${item.current_location?.name}`,
            label: "",
            cards: [],
          });
        } else {
          steps[i]['cards'].push(
            {
              id: `${item.id}`,
              folio: item.folio,
              order: item,
              customer:
                item.customer.first_name + " " + item.customer.last_name,
              unit:
                item.order_dynamic_data?.car_model?.brand?.name ||
                item.order_dynamic_data?.vehicle.model?.brand?.name,
              model:
                item.order_dynamic_data?.car_model?.name ||
                item.order_dynamic_data?.vehicle?.model?.name,
              assigned_to_id: item?.current_step?.assigned_to?.id,
              assigned_to:
                item?.current_step?.assigned_to?.first_name + ' ' +
                item?.current_step?.assigned_to?.last_name,
              step: item.current_step,
              type: item.order_type,
              vin: item.order_dynamic_data?.vehicle?.vin,
              license_plate: item.order_dynamic_data?.vehicle?.license_plate,
            }
          )
        }


      }
    })

    setLoading(false);
    setLoadingData(false);
    setDataSource({ ...dataSource, lanes: steps });
    return;

  }

  const getServices = async () => {
    try {
      setLoading(true);
      let steps = [];
      let stepsWorkFlow = allData.find(
        (element) => element.id == optionSelected
      );

      if (!stepsWorkFlow) return
      stepsWorkFlow["steps"].map((item) => {
        const labelMinutesHrs = item.max_time_in_minutes ? `${item.max_time_in_minutes > 60 ? `${Math.floor(item.max_time_in_minutes / 60)} hrs` : `${item.max_time_in_minutes} min`}` : '';
        const maxTime = item.max_time_in_minutes ? ` (${labelMinutesHrs})` : '';
        steps.push({
          id: `${item.id}`,
          title: `${item.name}${maxTime} ${item.step_type==='REPAIR' ? ' (R)' : '(P)'}`,
          label: "",
          cards: [],
        });
      });

      let response = await axiosApi.get(
        API.WORKSHOP.WORKSHOP_ORDER_ELECTROPLAN + "?page_size=1000000000&process_status=in_process&is_deleted=false"
      );

      if (optionSelected === 3 && subCategoryRepair === 2) {
        get_board_location(response.data.results)
        return
      }


      if (response.status === 200) {
        response.data.results.forEach((item) => {
          let step_id = item.current_step?.step?.id;
          if (step_id) {
            let idx = steps.findIndex((element) => element.id == step_id);
            if (idx > -1) {
              const itemCard = {
                id: `${item.id}`,
                folio: item.folio,
                order: item,
                customer: item.customer.first_name + " " + item.customer.last_name,
                unit: item.order_dynamic_data?.car_model?.brand?.name || item.order_dynamic_data?.vehicle?.model?.brand?.name,
                model: item.order_dynamic_data?.car_model?.name || item.order_dynamic_data?.vehicle?.model?.name,
                assigned_to_id: item?.current_step?.assigned_to?.id,
                assigned_to: item?.current_step?.assigned_to?.first_name + ' ' + item?.current_step?.assigned_to?.last_name,
                step: item.current_step,
                type: item.order_type,
                vin: item.order_dynamic_data?.vehicle?.vin,
                license_plate: item.order_dynamic_data?.vehicle?.license_plate,
              };
              steps[idx]["cards"].push(itemCard);
            }
          }
        });
        setLoading(false);
        setLoadingData(false);
        setDataSource({ ...dataSource, lanes: steps });
      }
    } catch (error) {
      console.error("Error", error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoadingData(true)
    getInfo();
  }, []);

  document.addEventListener(
    "keydown",
    function (e) {
      if (e.key === "Escape") {
        toggleFullScreen(false);
      }
    },
    false
  );
  /* useEffect(() => {
    if(dataSource.lanes.length > 0 && loadColumns){
      getServices();
    }
  }, [dataSource]); */

  const toggleFullScreen = (boolean) => {
    setFullScreen(boolean);
  };

  const formatNumber = (number) => {
    return number.toLocaleString('es-MX'); // Formato para español
  };

  const changeColumns = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {

    if (sourceLaneId !== targetLaneId) {
      try {
        let data = {
          service_order: cardId,
          step: targetLaneId,
          status: "PLAY",
        };
        let response = await axiosApi.post(API.WORKFLOW.LOG, data);

        if (response.status > 200 && response.status < 400) {
          message.success({
            content: intl.formatMessage({ id: "saleOrder.saved" }),
          });
          getServices();
        }
      } catch (error) { }
    }

  };

  const refreshBoard = (data) => {
    eventBus.publish({
      type: 'REFRESH_BOARD',
      data: data,
    });
  }


  const cancelKModal = () => {
    setCurrentStep(null);
    setOrderAssigned_to(null);
    setOrderSelected(null)
    setVisibleModalService(false);
  }

  const changeStatusModal = (id, step, assigned) => {
    setOrderSelected(id);
    setCurrentStep(step.step);
    setOrderAssigned_to(assigned);;
    setVisibleModalService(true);
  }


  const changeStepStatus = async (action, stepId) => {
    //console.log(action, stepId);
    action = action === "PLAY" ? "stop" : "start";
    try {
      const res = await changeStatusStopStart(action, stepId);
      //console.log(res);
      if (!res.status) {
        message.error(intl.formatMessage({ id: "repair.statusChangeError" }));
      } else {
        message.success(
          intl.formatMessage({ id: "repair.statusChangeSuccess" })
        );
        getServices();
        /* getServiceDetail(id); */
      }
    } catch (e) {
      alert("Hubo un error, porfavor vuelva a intentar");
    }
  };


  const TitleCustomCard = ({ title, step, type, vin, orderNumber = 'N/A', order }) => {
    let history = useHistory();

    const goToDetails = () => {
      history.push(`/service-detail/${step.service_order}`)
      // if (type && type.code === "SALE") {
      //   history.push(`/sale-orders/tracking/${step.service_order}`);
      // } else {
      //   history.push(`/service-detail/${step.service_order}`)
      // }
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ marginTop: "auto", marginBottom: "auto", cursor: "pointer" }} onClick={() => goToDetails()}>
          {
            orderNumber === title ? <b style={{ color: 'blue' }}>{title}</b> :
              <b style={{ color: 'blue' }}>{title ? 'Folio: ' + title : ''} {orderNumber ? ', Orden: ' + orderNumber : ''}</b>
          }
          <br />
          <small>
            <b>
              VIN:
            </b>
            {vin}
          </small>
          <br />
          <small>
            <b>
              Locación:
            </b>
            {order?.current_location?.name ? order?.current_location?.name : 'Sin asignar'}
          </small>
          <br />
          {
            (order?.order_dynamic_data?.insurance && order?.order_dynamic_data?.insurance?.name) && <>
              <small>
                <b>
                  Aseguradora:
                </b>
                {"  "} {order?.order_dynamic_data?.insurance?.name}
                <br />
                <b>
                  Núm. Siniestro:
                </b>
                {"  "} {order?.order_dynamic_data?.sinister_number}
              </small>
            </>
          }
          <br />
        </span>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Popconfirm
            title={intl.formatMessage({
              id:
                step.status === "PLAY"
                  ? "repair.stopProcess"
                  : "repair.initProcess",
            })}
            onConfirm={() => changeStepStatus(step.status, step.id)}
          /* okButtonProps={{ loading: workflow.fetching }} */
          >
            {step.status === "PLAY" ? (
              <PauseCircleFilled
                style={{ fontSize: 22, cursor: "pointer" }}
              />
            ) : (
              <PlayCircleFilled style={{ fontSize: 22, cursor: "pointer", color: 'green' }} />
            )}
          </Popconfirm>
          {step.status === "PLAY" && (
            <Tag
              className="animate__animated animate__pulse animate__infinite"
              color={"processing"}
              icon={<ClockCircleFilled />}
              style={{ marginTop: 5 }}
            >
              {intl.formatMessage({
                id: "started",
              })}
            </Tag>
          )}
        </div>
        {/* <PauseCircleFilled style={{fontSize:22, cursor:'pointer' }}  /> */}
      </div>
    );
  }

  const getColorByTimeStep = (order) => {
    const { max_time_in_minutes } = order?.current_step?.step;
    const { time_tracked_in_minutes } = order?.current_step;
    const onTime = time_tracked_in_minutes <= max_time_in_minutes;
    if (max_time_in_minutes === null || max_time_in_minutes === undefined || max_time_in_minutes === 0) return 'gray';
    if (onTime) {
      return 'blue';
    } else {
      return 'red';
    }
  };


  const CustomCard = ({
    id,
    folio,
    order,
    customer,
    unit,
    assigned_to,
    assigned_to_id,
    step,
    type,
    model,
    vin,
    license_plate,
  }) => {


    //console.log(order)

    return (
      <Card
        title={
          <>
            <Space direction="vertical" style={{ width: '100%' }}>
              {
                !order?.delivery_promise_date && <Alert type="warning" showIcon={false} banner message={"Sin fecha promesa"} />
              }
              <TitleCustomCard title={folio} step={step} type={type} vin={vin} order={order} orderNumber={order?.order_number} />
            </Space>
          </>

        }
        className="custom-card-slectroplan"
        style={{ borderRadius: 5, marginBottom: 10, border: `3px solid ${getColorByTimeStep(order)}` }}
      >

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ul>
              <li>{`${unit && unit}  ${model && model} ${order?.order_dynamic_data?.vehicle?.color?.name} `}</li>
              {
                (license_plate != null && HIDE_LICENSE_PLATE_INPUT === '0') &&
                <li>{`${"Placas:"} ${license_plate && license_plate}`}</li>
              }
              {
                order?.order_dynamic_data?.vehicle?.economic_number &&
                <li>{`${ECONOMIC_NUMBER_LABEL ? ECONOMIC_NUMBER_LABEL : 'Núm. económico'}: ${order?.order_dynamic_data?.vehicle?.economic_number}`}</li>
              }
            </ul>
            <br />
            {
              order?.customer?.person_type === 'PF' ?
                <Text style={{ fontSize: 12 }}><b>Cliente:</b> {order?.customer?.first_name?.substring(0, 20) + ' ' + order?.customer?.last_name}</Text> :
                <Text style={{ fontSize: 12 }}><b>Empresa:</b> {order?.customer?.company_name?.substring(0, 20)}</Text>
            }

            <Text style={{ fontSize: 12 }}><b>Asignado a:</b> {assigned_to}</Text>
            {
              order?.delivery_promise_date ? <Text style={{ fontSize: 12 }}><b>Fecha promesa:</b> {moment(order?.delivery_promise_date).format("DD/MM/YYYY hh:mm a")}</Text> :
                null
            }

          </div>



        </div>
        <Space size={0} direction="vertical" style={{ width: '100%' }}>
          <KButton
            size={"small"}
            style={{ marginTop: 20 }}
            text={"Cambiar estatus"}
            onClick={() => changeStatusModal(id, step, assigned_to_id)}
          />
          <b>
            {`
              Tiempo transcurrido`}
          </b>
          <small style={{ fontSize: 12, padding: 0, margin: 0 }}>
            {
              order?.current_step?.time_tracked_in_minutes && order?.current_step?.time_tracked_in_minutes >= 60 ?
                `
              ${order?.current_step?.time_tracked_in_minutes ? formatNumber((order?.current_step?.time_tracked_in_minutes / 60)) : 0} horas `
                :
                `
              ${order?.current_step?.time_tracked_in_minutes ? formatNumber(order?.current_step?.time_tracked_in_minutes) : 0} minutos `
            }
          </small>

        </Space>

      </Card>
    );
  };


  const changeWorkFlow = (val) => {
    setOptionSelected(val);
    setLoadingData(true);
    let current = allData.find((element) => element.id == val);
    if (current) {
      setCurrentWorkFlow(current.order_type?.code);
      setAssignmentRequired(current.assignment_required);
    }
  }


  const finishRepairProcess = async (step = -1, data = null) => {
    setLoading(true)
    try {
      if (step === -2 && data) {
        data.close_order = true;
      }
      const res = await finishRepair(orderSelected, data)
      if (res.success) {
        message.success(intl.formatMessage({ id: 'repair.statusChangeSuccess' }));
        getServices();
        setVisibleModalService(false);
      } else {
        message.error(res?.error?.response?.data?.message ? res?.error?.response?.data?.message : 'Hubo un error al cambiar de estatus, porfavor vuelve a intentar');
      }
      setSaving(false);

      // si es step -2 entonces significa que hay que hacer tambi{en la entrega}
      // if(step===-2 && data){
      //   closeOrderService(data)
      // }else{
      //   setTimeout(()=>{
      //     //setLoading(false)
      //     window.location.reload();
      //   }, 500);
      // }

    } catch (e) {
      alert('Hubo un error, porfavor vuelva a intentar')
    } finally {
      setSaving(false);
      setVisibleModalService(false);
    }

  }


  const onFinish = async (values) => {

    if (values.step === -1 || values.step === -2) {
      setLoading(true)
      finishRepairProcess(values.step, values)
    } else {
      setLoading(true)
      setSaving(true);
      try {
        const res = assignStepFlow(values);
        getServices();
        setSaving(false);
        setVisibleModalService(false);
      } catch (e) {

      } finally {
        setSaving(false);
        setVisibleModalService(false);
      }
      // assignStepFlow(values).then(() => {
      //
      //   setSaving(false);
      //   setVisibleModalService(false);
      //   /* getServiceDetail(id); */
      //   /* if (serviceOrder?.serviceDetail) {
      //     getHistory("WORKSHOP", serviceOrder.serviceDetail.id);
      //   } */
      // });
    }


  };


  return (
    <>
      <Global
        styles={css`
          .relative {
            position: relative;
          }
          .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #ffffffb3;
            z-index: 20;
            text-align: center;
            vertical-align: middle;
            align-items: center;
            display: flex;
          }
          /* .m-auto{
            margin: auto;
          } */
        `}
      />
      <MainLayoutv2 title="Electroplán" index="electroplan">
        <Layout>
          {/* <TabsOptions fullScreen={toggleFullScreen} /> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Space>
              <Select
                style={{ width: 150 }}
                value={optionSelected}
                onChange={(val) => changeWorkFlow(val)}
              >
                {allData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              {
                optionSelected === 3 &&
                <Select
                  style={{ width: 150 }}
                  value={subCategoryRepair}
                  onChange={(e) => setSubCategoryRepair(e)}
                >
                  <Option key={1} value={1}>
                    Por estatus
                  </Option>
                  <Option key={2} value={2}>
                    Por ubicación
                  </Option>
                </Select>
              }
              <KButton text="Vista ligera" style={{ marginLeft: 10, color: 'blue' }} type="link" icon={<TableOutlined />} onClick={() => history.push('/electroplan-light')} />
            </Space>

            <Button
              className="btns_services"
              icon={<FullscreenOutlined />}
              onClick={() => toggleFullScreen(true)}
            >
              FullScreen
            </Button>
          </div>

          <Divider style={{ margin: "15px 0px" }} />
          {/* {loading ? (
          <Spin spinning={true} />
        ) : ( */}
          <div style={!fullScreen ? { position: "relative" } : null}>
            {loadingData && (
              <div className="overlay">
                <Spin spinning={true} className="m-auto" />
              </div>
            )}
            <Board
              data={dataSource}
              className={fullScreen && "boardFullScreen"}
              handleDragEnd={changeColumns}
              cardDraggable
              components={{ Card: CustomCard }}
            />
          </div>
          {/* )} */}
          <KModal
            title={intl.formatMessage({ id: "servicesDetails.repair" })}
            onCancel={() => cancelKModal()}
            visible={visibleModalService}
          >
            <FormProcessChange
              onCancel={() => cancelKModal()}
              stepDefault={currentStep}
              orderAssigned_to={orderAssigned_to}
              loading={saving}
              onSubmit={onFinish}
              orderId={orderSelected}
              workflowCode={currentWorkFlow}
              assignmentRequired={assignmentRequired}
            />
          </KModal>
        </Layout>
      </MainLayoutv2>
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
});

export default injectIntl(
  connect(mapStateToProps, { assignStepFlow, changeStatusStopStart, finishRepair })(
    Electroplan_
  )
);

import generateStore from "../../redux/store";
import moment from 'moment';



export let permissionsExist = (permissions = [], codename = "") => {

    if (permissions && permissions.length > 0) {
        return permissions.some(function (el) {
            return el.codename === codename;
        });
    } else {
        return false
    }

}

export const playNotification = () => {
    let audio = new Audio('../notification.mp3');
    audio.load();
    audio.play();
}

export const getTypeFile = (file) => {
    let arrayType = file.split("/");
    let type = arrayType[arrayType.length - 1]
    return type;
}

export const getCleanDateTime = (date, isMomentObject = false) => {
    return isMomentObject ? date.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') : moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
}

export const isImage = (file) => {
    let arrayType = file.split("/");
    let type = arrayType[arrayType.length - 1].split('.')[1]
    if (type === "png" || type === "jpg" || type === "jpeg") {
        return true
    } else {
        return false
    }
}

export const isVideo = (file) => {
    let arrayType = file.split("/");
    let type = arrayType[arrayType.length - 1].split('.')[1]
    if (type === "mp4" || type === "mov") {
        return true
    } else {
        return false
    }
}

export let downloadFile = (data, filename, mime) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([data], { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename + '_' + moment().format('DDMMYYYY'));
        return;
    }
    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename + '_' + moment().format('DDMMYYYY'));
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

export const subtypes = [
    { value: 'STANDARD', label: 'Estándar' },
    { value: 'GUARANTEE', label: 'Garantía' },
    { value: 'INTERNAL', label: 'Interno' }
]


export const version = { version: '25.01', date: '01.01.2025', url: 'https://medium.com/kikert/kikert-25-01-está-aquí-431df14995bb' }


export const convertirBytes = (bytes) => {
    const unidades = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;

    while (bytes >= 1024) {
        bytes /= 1024;
        i++;
    }

    return bytes.toFixed(2) + ' ' + unidades[i];
}


export const queryStringToObject = url =>
    [...new URLSearchParams(url.split('?')[1])].reduce(
        (a, [k, v]) => ((a[k] = v), a),
        {}
    );

export const stateList = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas"
]

export const camelize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


export const colorConOpacidad = (colorHex) => {
    // Verifica si el color es un valor hexadecimal válido
    if (!/^#([A-Fa-f0-9]{6})$/.test(colorHex)) {
        throw new Error('Color hexadecimal no válido');
    }

    // Agrega el valor de opacidad (80 en hexadecimal es 50% de opacidad)
    return `${colorHex}80`;
}
import React, { useState, useEffect } from 'react'
import { Menu, Typography, Card, Row, Col, Form, Descriptions, BackTop, List, Space, message } from 'antd';
import { FieldTimeOutlined, WarningOutlined, CopyOutlined, CheckSquareOutlined, ToolOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from 'react-redux'
import { changeLangAction } from '../../redux/settingsDuck';
import { getLicenseStatus } from '../../redux/securitryDuck';
import CardStatistics from "../../components/CardStatistics/CardStatistics";
import { useHistory, useParams } from "react-router-dom";
import {
    getWidgetDashboard
} from "../../redux/dashboardDuck";
import PieChartKikert from "../../components/Charts/PieChartKikert";
import BarChartKikert from "../../components/Charts/BarChartKikert";
import MainLayoutv2 from '../../layouts/MainLayoutv2';
import { tenantSelected } from '../../lib/utils/axiosApi';

const menu = (
    <Menu>
        <Menu.Item>
            Configuraciones
        </Menu.Item>
    </Menu>
);

const DashboardPage = ({ changeLangAction, intl, settingsStore, getDeliveryToday, dashboardStore,
    getWidgetDashboard, getLicenseStatus, ...props }) => {


    const [numRepair, setNumRepair] = useState(null)
    const [numProcess, setNumProcess] = useState(null)
    const { Meta } = Card;
    const { Title, Text } = Typography;
    let history = useHistory();

    useEffect(() => {
        getLicenseStatus()
        getDashboardData()

        // Configurar la actualización cada 5 minutos
        const intervalId = setInterval(() => {
            getDashboardData();
        }, 5 * 60 * 1000); // 5 minutos en milisegundos

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId);


    }, [])

    const getDashboardData = () => {
        getWidgetDashboard('DELIVERY_TODAY', 'count')
        getWidgetDashboard('LATE_DELIVERY', 'count')
        getWidgetDashboard('OPEN_ORDERS', 'count')
        getWidgetDashboard('OPEN_ORDERS_BY_LOCATION', 'array')
        getWidgetDashboard('ON_TIME_DELIVERY', 'count')
        getWidgetDashboard('OPEN_ORDERS_BY_INSURANCE_TYPE', 'array')
        getWidgetDashboard('OPEN_ORDERS_BY_INSURANCE', 'array')
        getWidgetDashboard('OPEN_ORDERS_BY_ASSIGNED_TO', 'array')
        getWidgetDashboard('OPEN_ORDERS_BY_COLOR', 'array')
        getWidgetDashboard('OPEN_ORDERS_BY_BRAND_MODEL', 'array')
        getWidgetDashboard('REOPEN_ORDERS_BY_REASON', 'array')
        getWidgetDashboard('OPEN_ORDERS_BY_BRAND', 'array')
        getWidgetDashboard('REOPEN_ORDERS', 'count')
        getWidgetDashboard('OPEN_ORDERS_BY_REPAIR_STATUS', 'array').then((res) => {
            if (res.data) {
                // Separamos los conteos por tipo
                let repairCount = 0;
                let processCount = 0;
                res.data.forEach(item => {
                    if (item.step_type === 'REPAIR') {
                        repairCount += item.count;
                    } else {
                        processCount += item.count;
                    }
                });
                
                setNumRepair(repairCount);
                setNumProcess(processCount);
            }
        })
    }

    const goToPage = (url) => {
        history.push(url)
    }


    const copyLink = () => {
        //obtener el link de la pagina actual y agregarle /client/public/service-detail/check    si tiene algun elemento como # o @ hay que omitirlo

        const currentUrl = window.location.host;
        const newUrl = `${currentUrl}/client/public/service-detail/check`;
        navigator.clipboard.writeText(newUrl);
        message.success('Link copiado al portapapeles');
    };


    return (
        <MainLayoutv2 title={<FormattedMessage id="dashboard.myDashboard" />} index="Dashboard">
            <BackTop />
            <Row>
                <Col>
                    <Form>

                    </Form>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        <p style={{ color: 'gray', fontSize: 12 }}>
                            <InfoCircleOutlined /> Esta información se actualiza cada 5 minutos.
                        </p>
                    </Space>

                </Col>
                <Col xs={24} lg={12} md={12} style={{ marginBottom: 10 }}>
                    <CardStatistics
                        extra={<a href="/services/new">Crear nuevo</a>}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{ fontSize: 40, marginTop: 30 }} />}
                        title={'Servicios  abiertos'}
                        textDetail={'Ver detalle'}
                        onDetail={() => goToPage('/services/list/?filters=status=OPEN')}
                        value={dashboardStore.openOrders} />
                    <a href="#" onClick={copyLink} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>
                        <CopyOutlined /> Obtener link de consulta rápida de órdenes para clientes
                    </a>
                </Col>
                <Col xs={24} lg={12} md={12} style={{ marginBottom: 10 }}>
                    <CardStatistics
                        extra={<a href="/services/new">Crear nuevo</a>}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{ fontSize: 40, marginTop: 30 }} />}
                        title={'Servicios  reabiertos'}
                        textDetail={'Ver detalle'}
                        onDetail={() => goToPage('/services/list/?filters=status=RE-OPENED')}
                        value={dashboardStore?.reopenOrders} />
                </Col>

                <Col xs={24} lg={12} md={12}>
                    <CardStatistics
                        extra={<a href="/electroplan_">Electroplan</a>}
                        icon={<ToolOutlined style={{ fontSize: 40, marginTop: 30, color: 'gray' }} />}
                        title={'Servicios en reparación'}
                        //textDetail={'Ver detalle'}
                        //onDetail={() => goToPage('/services/list/?filters=repair_status=2')}
                        value={numRepair ? numRepair : 0} />
                </Col>

                <Col xs={24} lg={12} md={12}>
                    <CardStatistics
                        extra={<a href="/electroplan_">Electroplan</a>}
                        //icon={<ToolOutlined style={{ fontSize: 40, marginTop: 30, color: 'gray' }} />}
                        title={'Servicios en proceso'}
                        //textDetail={'Ver detalle'}
                        //onDetail={() => goToPage('/services/list/?filters=repair_status=2')}
                        value={numProcess ? numProcess : 0} />
                </Col>


                <Col xs={24} lg={12} style={{ marginTop: 10 }} md={12}>
                    <CardStatistics
                        icon={<CheckSquareOutlined style={{ fontSize: 40, marginTop: 30, color: 'green' }} />}
                        textDetail={'Ver detalle'}
                        onDetail={() => goToPage('/services/list/?filters=status=OPEN&delivery_promise_date_status=1')}
                        title={'Servicios en tiempo'}
                        value={dashboardStore.onTimeDelivery} />
                </Col>






            </Row>
            <Row gutter={16} style={{ marginTop: 20 }}>
                <Col xs={24} lg={12} md={12}>
                    <CardStatistics
                        icon={<WarningOutlined style={{ fontSize: 40, marginTop: 30, color: 'red' }} />}
                        textDetail={'Ver detalle'}
                        onDetail={() => goToPage('/services/list/?filters=status=OPEN&delivery_promise_date_status=0')}
                        title={'Entregas atrasadas'}
                        value={dashboardStore.lateDelivery} />
                </Col>
                <Col xs={24} lg={12} md={12}>
                    <CardStatistics
                        icon={<FieldTimeOutlined
                            style={{ fontSize: 40, marginTop: 30, color: 'orange' }} />}
                        title={'Unidades que se entregan hoy'}
                        textDetail={'Ver detalle'}
                        onDetail={() => goToPage('/services/list/?filters=status=OPEN&delivery_promise_date_status=2')}
                        value={dashboardStore.deliveryToday} />
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }} gutter={16}>


            
                

                <Col xs={24} lg={24} md={12}>
                    <CardStatistics
                        title={`Servicios en proceso (${numRepair})`}
                        type={'chart'}
                        body={
                            <Row>
                                <Col lg={16} md={16}>
                                    {
                                        dashboardStore?.ordersByRepairStatus &&

                                        <PieChartKikert
                                            categoryField={'name'}
                                            valueField={'count'}
                                            isDonut={false}
                                            idChart={'ordersByRepairStatus'}
                                            data={dashboardStore?.ordersByRepairStatus} />
                                    }
                                </Col>
                                <Col lg={8} md={8}>
                                    {
                                        dashboardStore?.ordersByRepairStatus &&
                                        <List
                                            header={<div><Title level={3}>Promedio en días</Title></div>}
                                            bordered
                                            dataSource={dashboardStore?.ordersByRepairStatus}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <Typography.Text mark>[{item?.name}]</Typography.Text> <b>{item?.avg_days_in_process} días</b>
                                                </List.Item>
                                            )}
                                        />
                                    }

                                </Col>
                            </Row>

                        }
                    />
                </Col>

                <Col xs={24} lg={24} md={12} style={{ marginTop: 20, marginBottom: 20 }}>
                    <CardStatistics
                        title={`Razones de reapertura`}
                        type={'chart'}
                        body={
                            <Row>
                                <Col lg={24} md={24}>
                                    {
                                        dashboardStore?.reopenOrdersReason &&

                                        <PieChartKikert
                                            categoryField={'reason'}
                                            valueField={'count'}
                                            isDonut={false}
                                            idChart={'reopenOrdersReason'}
                                            data={dashboardStore?.reopenOrdersReason} />
                                    }
                                </Col>
                            </Row>

                        }
                    />
                </Col>




            </Row>
            <Row style={{ marginTop: 30 }}>
                <Col xs={24} lg={24} md={24}>
                    <CardStatistics
                        icon={<CheckSquareOutlined className={'text-primary'} style={{ fontSize: 40, marginTop: 30 }} />}
                        title={'Locación'}
                        type={'chart'}
                        body={
                            <BarChartKikert
                                categoryField={'location'}
                                valueField={'count'}
                                isDonut={true}
                                idChart={'barlocation'}
                                data={dashboardStore?.openOrdersByLocation}
                            />
                        } />
                </Col>
            </Row>
            {
                (settingsStore && settingsStore?.insuranceIsActive) && <Row style={{ marginTop: 30 }}>
                    <Col xs={24} lg={24} md={24}>

                        <CardStatistics
                            title={'Por tipo de servicio'}
                            type={'chart'}
                            body={
                                <Row>
                                    <Col lg={12} md={24}>
                                        {
                                            dashboardStore?.ordersByInsuranceType &&
                                            <PieChartKikert
                                                categoryField={'name'}
                                                valueField={'count'}
                                                isDonut={true}
                                                idChart={'chartDiv'}
                                                data={dashboardStore?.ordersByInsuranceType} />
                                        }
                                    </Col>
                                    <Col lg={12} md={24}>
                                        {
                                            dashboardStore?.ordersByInsurance &&
                                            <List
                                                style={{
                                                    maxHeight: '400px', // Ajusta este valor según tus necesidades
                                                    overflowY: 'scroll',
                                                    border: '1px solid gray'
                                                }}
                                                className="demo-loadmore-list"
                                                size={'large'}
                                                split={true}
                                                dataSource={dashboardStore?.ordersByInsurance}
                                                renderItem={(item) => (
                                                    <List.Item
                                                        key={item.id}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={<img
                                                                width={70}
                                                                alt="logo"
                                                                src={item?.logo_thumbnail}
                                                            />}
                                                            title={<Title level={3}>{item.count}</Title>}
                                                            description={item.name}
                                                        />
                                                    </List.Item>

                                                )}
                                            />
                                        }
                                    </Col>
                                </Row>

                            }


                        />
                    </Col>
                </Row>
            }


            <Row style={{ marginTop: 30 }} gutter={16}>

                <Col xs={24} lg={12} md={24}>
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{ fontSize: 40, marginTop: 30 }} />}
                        title={'Servicios por asesor'}
                        body={
                            <BarChartKikert
                                categoryField={'advisor'}
                                valueField={'count'}
                                isDonut={true}
                                idChart={'orderassigsnetto'}
                                data={dashboardStore?.ordersByAssignedTo}

                            />
                        } />
                </Col>

                <Col xs={24} lg={12} md={12}>

                    <CardStatistics
                        title={'Por Color'}
                        type={'chart'}
                        body={
                            <Row>
                                <Col lg={24} md={24}>
                                    {
                                        dashboardStore?.openOrdersByColors &&
                                        <PieChartKikert
                                            categoryField={'color'}
                                            valueField={'count'}
                                            isDonut={false}
                                            idChart={'chartDivByColor'}
                                            data={dashboardStore?.openOrdersByColors} />
                                    }
                                </Col>
                            </Row>

                        }


                    />
                </Col>

            </Row>

            {
                tenantSelected !== 'jacmexico' &&
                <Row style={{ marginTop: 30 }} gutter={16}>
                    <Col span={24}>
                        <CardStatistics
                            type={'chart'}
                            icon={<CheckSquareOutlined className={'text-primary'} style={{ fontSize: 40, marginTop: 30 }} />}
                            title={'Órdenes por marcas'}
                            body={
                                dashboardStore?.ordersByBrand ? <BarChartKikert
                                    withScroll={true}
                                    categoryField={'name'}
                                    valueField={'count'}
                                    isDonut={true}
                                    idChart={'bybrand'}
                                    data={dashboardStore?.ordersByBrand}

                                /> : null
                            } />
                    </Col>
                </Row>
            }


            <Row style={{ marginTop: 30 }} gutter={16}>
                <Col span={24}>
                    <CardStatistics
                        type={'chart'}
                        icon={<CheckSquareOutlined className={'text-primary'} style={{ fontSize: 40, marginTop: 30 }} />}
                        title={'Órdenes por modelo'}
                        body={
                            dashboardStore?.ordersByBrandModel ? <BarChartKikert
                                withScroll={true}
                                categoryField={'name'}
                                valueField={'count'}
                                isDonut={true}
                                idChart={'bybrandmodel'}
                                data={dashboardStore?.ordersByBrandModel}

                            /> : null
                        } />
                </Col>
            </Row>


            {/*<img src={dash} width="100%" height="100%" />*/}
        </MainLayoutv2>
    )
}

const mapState = (state) => ({
    settingsStore: state.settings,
    dashboardStore: state.dashboardStore
});

export default injectIntl(connect(mapState, {
    changeLangAction,
    getWidgetDashboard,
    getLicenseStatus
})(DashboardPage)) 
